import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import SinglePageHeader from '../components/singlepageheader';
import { SEO } from "../components/seo";

import { services } from "../components/services_list";

const OurServices = () => {
    return(
        <div className="container">
            <SEO title="Our Services" />
            <Header />
            <SinglePageHeader page="Our Services" />
            <div className="section services single">
                <div className="innerwrap">

                    <div className="services-details">
                        {services.map((service, index) => {
                            return(
                                <div id={service.href} key={index} className={`service ${index%2 == 0 ? "even": "odd"}`}>
                                    <div className="imageholder">
                                        <img src={service.img} alt={service.title} />
                                    </div>
                                    <div className="desc">
                                        <h2>{service.title}</h2>
                                        <p>
                                            {service.desc}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OurServices;