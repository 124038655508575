import React from "react";

const SinglePageHeader = ({ page, subtitle }) => {
    return(
        <div className="single-page-header">
            <h2>{page}</h2>
            <div className="breakcrums">
                {subtitle ? <>
                    <p> {subtitle()} </p>
                </> : <>
                    <span>Home</span>
                    <span> <ion-icon name="chevron-forward-outline"></ion-icon> </span>
                    <span>{page}</span>
                </>}
            </div>
        </div>
    )
}

export default SinglePageHeader;